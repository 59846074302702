<template>
  <v-container
    fluid
    class="pa-3 pos-relative fill-height align-stretch"
  >
    <img
      class="logo-PA"
      src="~/assets/images/game3/teasing/desktop/PA/PA.svg"
    />

    <v-row>
      <v-col
        cols="6"
        class="d-flex flex-column pa-0"
      >
        <LandingPagesTF3TeasingDesktopLeft />
      </v-col>
      <v-col
        cols="6"
        class="d-flex flex-column pa-0"
      >
        <LandingPagesTF3TeasingDesktopRight />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.pos-relative {
  position: relative;
}
.logo-PA {
  width: 175px;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
</style>
