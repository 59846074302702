<template>
  <div class="fill-height pos-relative bg-primary">
    <div class="wrapper-background-image">
      <img
        class="full-background"
        src="~/assets/images/game3/teasing/desktop/right/bg-striped/bg-striped@2x.png"
        srcset="
          ~/assets/images/game3/teasing/desktop/right/bg-striped/bg-striped@1x.png 1x,
          ~/assets/images/game3/teasing/desktop/right/bg-striped/bg-striped@2x.png 2x,
          ~/assets/images/game3/teasing/desktop/right/bg-striped/bg-striped@3x.png 3x
        "
      />

      <img
        class="confetti-right-top"
        src="~/assets/images/game3/teasing/desktop/right/confettis-top/confettis-top@2x.png"
        srcset="
          ~/assets/images/game3/teasing/desktop/right/confettis-top/confettis-top@1x.png 1x,
          ~/assets/images/game3/teasing/desktop/right/confettis-top/confettis-top@2x.png 2x,
          ~/assets/images/game3/teasing/desktop/right/confettis-top/confettis-top@3x.png 3x
        "
      />
      <img
        class="confetti-right-bottom"
        src="~/assets/images/game3/teasing/desktop/right/confettis-bottom/confettis-bottom@2x.png"
        srcset="
          ~/assets/images/game3/teasing/desktop/right/confettis-bottom/confettis-bottom@1x.png 1x,
          ~/assets/images/game3/teasing/desktop/right/confettis-bottom/confettis-bottom@2x.png 2x,
          ~/assets/images/game3/teasing/desktop/right/confettis-bottom/confettis-bottom@3x.png 3x
        "
      />
    </div>

    <div class="content font-weight-500 fill-height">
      <div class="wrapper">
        <div class="outlined mb-8 bg-primary">Jusqu'au 31/12/2025</div>

        <div>
          <AppTitle
            text="La Grande"
            :width="510"
            :height="90"
            :y="74"
            :font-size="90"
            :stroke-width="6"
            :shadow-offset="3"
            text-uppercase
          />
        </div>
        <div class="mt-n4">
          <AppTitle
            text="Boutique"
            :width="460"
            :height="90"
            :y="70"
            :font-size="90"
            :stroke-width="8"
            :shadow-offset="4"
            text-uppercase
          />
        </div>

        <div class="mt-8 font-size-24px">
          Transformez vos achats en objets collector !
        </div>

        <div>Valable sur les produits Lustucru Sélection au rayon frais.</div>
        <div class="mb-10">
          <nuxt-link
            :to="{
              path: localePath('eligible-products'),
              hash: t(URL_HASH_FOR_SHOP),
            }"
          >
            Voir la liste des produits éligibles
          </nuxt-link>
        </div>
        <div class="mb-5">
          <AppButtonBigTertiary
            :to="localePath('shop')"
            width="240px"
          >
            La Grande Boutique
          </AppButtonBigTertiary>
        </div>

        <div class="wrapper-img mb-10">
          <img
            class="goodies-pa"
            src="~/assets/images/game3/teasing/desktop/right/goodies/goodies@2x.png"
            srcset="
              ~/assets/images/game3/teasing/desktop/right/goodies/goodies@1x.png 1x,
              ~/assets/images/game3/teasing/desktop/right/goodies/goodies@2x.png 2x,
              ~/assets/images/game3/teasing/desktop/right/goodies/goodies@3x.png 3x
            "
          />

          <img
            class="pot-holder-blue"
            src="~/assets/images/landing-pages/tf2-teasing/pot-holder-blue@2x.png"
          />
          <img
            class="pot-holder-red"
            src="~/assets/images/landing-pages/tf2-teasing/pot-holder-red@2x.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { URL_HASH_FOR_SHOP } from '~/utils/constants'
const localePath = useLocalePath()
const { t } = useI18n()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.pos-relative {
  position: relative;
}
.wrapper-background-image {
  z-index: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.full-background {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.confetti-right-top {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
}
.confetti-right-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  position: relative;
  z-index: 10;

  display: flex;
  align-items: center;
}

.wrapper {
  width: 100%;
  padding-top: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlined {
  color: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 0 5px;
}

.wrapper-img {
  width: 100%;
  position: relative;
  text-align: center;
}
.goodies-pa {
  width: 50%;
  position: relative;
  left: 2%;
}
.pot-holder-blue {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 25%;
}
.pot-holder-red {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 25%;
}
</style>
