import type { transform } from 'lodash-es';
<template>
  <div class="position-relative">
    <img
      class="image-background"
      src="~/assets/images/game3/teasing/mobile/bottom/bg-striped/bg-striped@2x.png"
      srcset="
        ~/assets/images/game3/teasing/mobile/bottom/bg-striped/bg-striped@1x.png 1x,
        ~/assets/images/game3/teasing/mobile/bottom/bg-striped/bg-striped@2x.png 2x,
        ~/assets/images/game3/teasing/mobile/bottom/bg-striped/bg-striped@3x.png 3x
      "
    />
    <img
      class="pot-holder-red"
      src="~/assets/images/game3/teasing/mobile/bottom/pot-holder-red/pot-holder-red@2x.png"
      srcset="
        ~/assets/images/game3/teasing/mobile/bottom/pot-holder-red/pot-holder-red@1x.png 1x,
        ~/assets/images/game3/teasing/mobile/bottom/pot-holder-red/pot-holder-red@2x.png 2x,
        ~/assets/images/game3/teasing/mobile/bottom/pot-holder-red/pot-holder-red@3x.png 3x
      "
    />

    <v-img
      class="align-center"
      src="~/assets/images/game3/teasing/mobile/bottom/confettis/confettis@2x.png"
      :srcset="`
        ${Bg1X} 1x,
        ${Bg2X} 2x,
        ${Bg3X} 3x
      `"
    >
      <div class="content text-center">
        <div class="outlined mb-2 bg-primary">Jusqu'au 31/12/2025</div>

        <div class="mt-5">
          <AppTitle
            text="La Grande"
            :width="265"
            :height="48"
            :y="38"
            :font-size="48"
            :stroke-width="5"
            :shadow-offset="2"
            text-uppercase
          />
        </div>
        <div class="mb-1 mt-n2">
          <AppTitle
            text="Boutique"
            :width="245"
            :height="54"
            :y="38"
            :font-size="48"
            :stroke-width="5"
            :shadow-offset="2"
            text-uppercase
          />
        </div>

        <div>
          <AppButtonTertiary
            :to="localePath('shop')"
            width="180px"
            class="font-size-16px"
          >
            Visiter la Boutique
          </AppButtonTertiary>
        </div>

        <div class="mt-6 font-size-14px">
          Transformez vos achats<br />en objets collector !
        </div>

        <div class="font-size-8px">
          Valable sur les produits Lustucru Sélection au rayon frais.
        </div>
        <div class="font-size-8px">
          <nuxt-link
            :to="{
              path: localePath('eligible-products'),
              hash: t(URL_HASH_FOR_SHOP),
            }"
          >
            Voir la liste des produits éligibles
          </nuxt-link>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script setup lang="ts">
import { URL_HASH_FOR_SHOP } from '~/utils/constants'
import Bg1X from '~/assets/images/game3/teasing/mobile/bottom/confettis/confettis@1x.png'
import Bg2X from '~/assets/images/game3/teasing/mobile/bottom/confettis/confettis@2x.png'
import Bg3X from '~/assets/images/game3/teasing/mobile/bottom/confettis/confettis@3x.png'
const localePath = useLocalePath()
const { t } = useI18n()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.image-background {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.pot-holder-red {
  z-index: 1;
  position: absolute;
  bottom: -60px;
  right: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  // margin: 30px 0 50px 0;
}

.outlined {
  color: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 0 5px;
}
</style>
