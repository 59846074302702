<template>
  <div class="fill-height pos-relative bg-tertiary">
    <div class="wrapper-background-image">
      <img
        class="background-image-top"
        src="~/assets/images/game3/teasing/desktop/left/confettis-top/confettis-top@2x.png"
        srcset="
          ~/assets/images/game3/teasing/desktop/left/confettis-top/confettis-top@1x.png 1x,
          ~/assets/images/game3/teasing/desktop/left/confettis-top/confettis-top@2x.png 2x,
          ~/assets/images/game3/teasing/desktop/left/confettis-top/confettis-top@3x.png 3x
        "
      />
      <img
        class="background-image-bottom"
        src="~/assets/images/game3/teasing/desktop/left/confettis-bottom/confettis-bottom@2x.png"
        srcset="
          ~/assets/images/game3/teasing/desktop/left/confettis-bottom/confettis-bottom@1x.png 1x,
          ~/assets/images/game3/teasing/desktop/left/confettis-bottom/confettis-bottom@2x.png 2x,
          ~/assets/images/game3/teasing/desktop/left/confettis-bottom/confettis-bottom@3x.png 3x
        "
      />
      <img
        class="background-image-bottom"
        src="~/assets/images/game3/teasing/desktop/left/gnocchi/gnocchi@2x.png"
        srcset="
          ~/assets/images/game3/teasing/desktop/left/gnocchi/gnocchi@1x.png 1x,
          ~/assets/images/game3/teasing/desktop/left/gnocchi/gnocchi@2x.png 2x,
          ~/assets/images/game3/teasing/desktop/left/gnocchi/gnocchi@3x.png 3x
        "
      />
    </div>

    <div class="content font-weight-500 fill-height">
      <div class="wrapper">
        <div class="outlined mb-10 bg-tertiary position-relative">
          <img
            class="image-helmet"
            src="~/assets/images/game3/teasing/desktop/left/helmet/helmet@2x.png"
            srcset="
              ~/assets/images/game3/teasing/desktop/left/helmet/helmet@1x.png 1x,
              ~/assets/images/game3/teasing/desktop/left/helmet/helmet@2x.png 2x,
              ~/assets/images/game3/teasing/desktop/left/helmet/helmet@3x.png 3x
            "
          />

          Du 08/04/2025 au 28/06/2025
        </div>

        <div class="mt-3">
          <AppTitle
            text="RDV dès le 8 avril"
            :width="220"
            :height="34"
            :y="24"
            :font-size="26"
            :stroke-width="3"
            :shadow-offset="2"
          />
        </div>
        <div class="mt-n3">
          <AppTitle
            text="pour participer au grand jeu"
            :width="350"
            :height="38"
            :y="24"
            :font-size="26"
            :stroke-width="3"
            :shadow-offset="2"
          />
        </div>
        <div class="mt-0">
          <AppTitle
            text="Les Jours Extraordinaires"
            :font-size="40"
            :width="565"
            :height="50"
            :stroke-width="5"
            :shadow-offset="3"
            text-uppercase
          />
        </div>

        <div class="mt-5 mb-3 font-size-24px">
          Tentez de gagner un séjour en famille au
        </div>

        <div class="wrapper-img mb-4">
          <img
            src="~/assets/images/game3/teasing/desktop/left/logo-parc/logo-parc@2x.png"
            srcset="
              ~/assets/images/game3/teasing/desktop/left/logo-parc/logo-parc@1x.png 1x,
              ~/assets/images/game3/teasing/desktop/left/logo-parc/logo-parc@2x.png 2x,
              ~/assets/images/game3/teasing/desktop/left/logo-parc/logo-parc@3x.png 3x
            "
          />
        </div>

        <div class="mb-10 position-relative">
          <img
            class="image-button-left"
            src="~/assets/images/game3/teasing/desktop/left/asterix/asterix@2x.png"
            srcset="
              ~/assets/images/game3/teasing/desktop/left/asterix/asterix@1x.png 1x,
              ~/assets/images/game3/teasing/desktop/left/asterix/asterix@2x.png 2x,
              ~/assets/images/game3/teasing/desktop/left/asterix/asterix@3x.png 3x
            "
          />
          <img
            class="image-button-right"
            src="~/assets/images/game3/teasing/desktop/left/idefix/idefix@2x.png"
            srcset="
              ~/assets/images/game3/teasing/desktop/left/idefix/idefix@1x.png 1x,
              ~/assets/images/game3/teasing/desktop/left/idefix/idefix@2x.png 2x,
              ~/assets/images/game3/teasing/desktop/left/idefix/idefix@3x.png 3x
            "
          />

          <AppButtonBigDisabledWithShadow width="240px">
            Verrouillé
          </AppButtonBigDisabledWithShadow>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.pos-relative {
  position: relative;
  color: $color-secondary;
}
.wrapper-background-image {
  z-index: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.background-image-top {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
}
.background-image-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  position: relative;
  z-index: 10;

  display: flex;
  align-items: center;
}

.wrapper {
  width: 100%;
  // padding-top: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlined {
  color: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 0 5px;
}

.image-helmet {
  position: absolute;
  left: -160px;
  bottom: -50px;
}

.wrapper-img {
  width: 100%;
  text-align: center;
}

.image-button-left {
  position: absolute;
  left: -200px;
  bottom: -50px;
}
.image-button-right {
  position: absolute;
  right: -220px;
  bottom: -180px;
  transform: translate(-50%, -50%);
}
</style>
