<template>
  <div class="fill-height pos-relative bg-minions-blue-light">
    <div class="wrapper-background-image">
      <img
        class="background-image"
        src="~/assets/images/landing-pages/tf2/confettis-game.png"
      />
      <img
        class="background-image-top-left"
        src="~/assets/images/landing-pages/tf2/minion.png"
        srcset="
          ~/assets/images/landing-pages/tf2/minion.png    1x,
          ~/assets/images/landing-pages/tf2/minion@2x.png 2x,
          ~/assets/images/landing-pages/tf2/minion@3x.png 3x
        "
      />
      <img
        class="background-image-bottom-left"
        src="~/assets/images/landing-pages/tf2/minions.png"
        srcset="
          ~/assets/images/landing-pages/tf2/minions.png    1x,
          ~/assets/images/landing-pages/tf2/minions@2x.png 2x,
          ~/assets/images/landing-pages/tf2/minions@3x.png 3x
        "
      />
      <img
        class="background-image-bottom-right"
        src="~/assets/images/landing-pages/tf2/confettis-game-bottom-right.png"
        srcset="
          ~/assets/images/landing-pages/tf2/confettis-game-bottom-right.png    1x,
          ~/assets/images/landing-pages/tf2/confettis-game-bottom-right@2x.png 2x,
          ~/assets/images/landing-pages/tf2/confettis-game-bottom-right@3x.png 3x
        "
      />
    </div>

    <div class="content font-weight-500 fill-height">
      <div class="content__legend bg-minions-blue-light hidden-xs">
        <em> *Visuels non contractuels </em>
      </div>
      <div class="wrapper">
        <div class="outlined mb-5 bg-minions-blue-light">
          Du 08/04/2025 au 28/06/2025
        </div>

        <div class="mt-3">
          <AppTitle
            text="Le jeu"
            :width="190"
            :height="60"
            :y="48"
            :font-size="60"
            :stroke-width="6"
            :shadow-offset="3"
            text-uppercase
          />
        </div>
        <div class="mt-n6">
          <AppTitle
            text="méchamment"
            :width="590"
            :height="94"
            :y="80"
            :font-size="86"
            :stroke-width="6"
            :shadow-offset="3"
            text-uppercase
          />
        </div>
        <div class="mt-n2 mb-3">
          <AppTitle
            text="gourmand !"
            :width="370"
            :height="58"
            :y="46"
            :font-size="60"
            :stroke-width="6"
            :shadow-offset="3"
            text-uppercase
          />
        </div>

        <div class="font-size-24px">
          Une chance de gagner plein de cadeaux Minions !
        </div>

        <div class="font-size-18px">
          Valable sur les gnocchi à poêler Lustucru Sélection.
        </div>
        <div class="font-size-18px mb-5">
          <nuxt-link
            :to="{
              path: localePath('eligible-products'),
              hash: t(URL_HASH_FOR_GAME),
            }"
          >
            Voir la liste des produits éligibles
          </nuxt-link>
        </div>
        <div class="mb-10">
          <AppButtonSecondary
            :to="localePath('game-3')"
            width="240px"
          >
            Le Jeu
          </AppButtonSecondary>
        </div>

        <div class="wrapper-img">
          <img
            src="~/assets/images/landing-pages/tf2/prizes.png"
            srcset="
              ~/assets/images/landing-pages/tf2/prizes.png    1x,
              ~/assets/images/landing-pages/tf2/prizes@2x.png 2x,
              ~/assets/images/landing-pages/tf2/prizes@3x.png 3x
            "
          />
        </div>
        <div>
          <img
            src="~/assets/images/landing-pages/tf2-teasing/Logo-Minions-FR.png"
            srcset="
              ~/assets/images/landing-pages/tf2-teasing/Logo-Minions-FR.png    1x,
              ~/assets/images/landing-pages/tf2-teasing/Logo-Minions-FR@2x.png 2x,
              ~/assets/images/landing-pages/tf2-teasing/Logo-Minions-FR@3x.png 3x
            "
          />
        </div>
        <div>© UCS LLC</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { t } = useI18n()
import { URL_HASH_FOR_GAME } from '@/utils/constants'
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.pos-relative {
  position: relative;
  color: $color-secondary;
}
.wrapper-background-image {
  z-index: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.background-image {
  position: absolute;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
}
.background-image-top-left {
  position: absolute;
  top: -10px;
  left: 50px;
}
.background-image-bottom-left {
  position: absolute;
  bottom: -12px;
  left: 0;
}
.background-image-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.content {
  position: relative;
  z-index: 10;

  display: flex;
  align-items: center;
}
.content__legend {
  position: absolute;
  bottom: 12px;
  right: 50px;
}

.wrapper {
  width: 100%;
  padding-top: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlined {
  color: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 0 5px;
}

.wrapper-img {
  width: 100%;
  text-align: center;
}
.img-minions {
  width: 50%;
}
</style>
