import type { transform } from 'lodash-es';
<template>
  <div class="position-relative">
    <img
      class="minion"
      src="~/assets/images/landing-pages/tf2-teasing/mobile/minion.png"
      srcset="
        ~/assets/images/landing-pages/tf2-teasing/mobile/minion.png    1x,
        ~/assets/images/landing-pages/tf2-teasing/mobile/minion@2x.png 2x,
        ~/assets/images/landing-pages/tf2-teasing/mobile/minion@3x.png 3x
      "
    />
    <img
      src="~/assets/logos/20-years.svg"
      class="logo-20-years"
    />

    <v-img
      class="bg-minions-blue-light align-center"
      src="~/assets/images/landing-pages/tf2-teasing/mobile/confettis-game.png"
      :srcset="`
        ${Bg1X} 1x,
        ${Bg2X} 2x,
        ${Bg3X} 3x
      `"
    >
      <div class="content">
        <div>
          <img
            class="d-block"
            src="~/assets/images/landing-pages/tf2-teasing/mobile/Logo-Minions-FR.png"
            srcset="
              ~/assets/images/landing-pages/tf2-teasing/mobile/Logo-Minions-FR.png    1x,
              ~/assets/images/landing-pages/tf2-teasing/mobile/Logo-Minions-FR@2x.png 2x,
              ~/assets/images/landing-pages/tf2-teasing/mobile/Logo-Minions-FR@3x.png 3x
            "
          />
        </div>
        <div class="font-size-6px mt-1 mb-2">© UCS LLC</div>

        <div class="outlined bg-minions-blue-light">
          Du 08/04/2025 au 28/06/2025
        </div>

        <div class="mt-3">
          <AppTitle
            text="Le jeu"
            :width="100"
            :height="32"
            :y="24"
            :font-size="30"
            :stroke-width="5"
            :shadow-offset="2"
            text-uppercase
          />
        </div>
        <div class="mt-n5">
          <AppTitle
            text="méchamment"
            :width="310"
            :height="52"
            :y="42"
            :font-size="44"
            :stroke-width="5"
            :shadow-offset="2"
            text-uppercase
          />
        </div>
        <div class="mt-n3">
          <AppTitle
            text="gourmand !"
            :width="195"
            :height="34"
            :y="24"
            :font-size="30"
            :stroke-width="5"
            :shadow-offset="2"
            text-uppercase
          />
        </div>

        <div>
          <AppButtonSecondary
            :to="localePath('game-3')"
            width="180px"
            class="font-size-16px"
          >
            Participer au Jeu
          </AppButtonSecondary>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script setup lang="ts">
import Bg1X from '~/assets/images/landing-pages/tf2-teasing/mobile/confettis-game.png'
import Bg2X from '~/assets/images/landing-pages/tf2-teasing/mobile/confettis-game@2x.png'
import Bg3X from '~/assets/images/landing-pages/tf2-teasing/mobile/confettis-game@3x.png'
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.minion {
  z-index: 1;
  position: absolute;
  top: -10px;
  left: 0;
}
.logo-20-years {
  z-index: 1;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  // padding-top: 50px;
  padding-bottom: 80px;
}

.outlined {
  color: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 0 5px;
}
</style>
