import type { transform } from 'lodash-es';
<template>
  <div class="position-relative">
    <img
      class="img-pa"
      src="~/assets/images/game3/teasing/mobile/PA/PA@2x.png"
      srcset="
        ~/assets/images/game3/teasing/mobile/PA/PA@1x.png 1x,
        ~/assets/images/game3/teasing/mobile/PA/PA@2x.png 2x,
        ~/assets/images/game3/teasing/mobile/PA/PA@3x.png 3x
      "
    />

    <v-img
      class="bg-tertiary align-center"
      src="~/assets/images/game3/teasing/mobile/top/confettis/confettis@2x.png"
      :srcset="`
        ${Bg1X} 1x,
        ${Bg2X} 2x,
        ${Bg3X} 3x
      `"
    >
      <div class="content">
        <div class="outlined bg-tertiary">Du 08/04/2025 au 28/06/2025</div>

        <div class="mt-3">
          <AppTitle
            text="RDV dès le 8 avril"
            :width="160"
            :height="28"
            :y="18"
            :font-size="16"
            :stroke-width="4"
            :shadow-offset="2"
          />
        </div>
        <div class="mb-0 mt-n3">
          <AppTitle
            text="pour participer au grand jeu"
            :width="225"
            :height="28"
            :y="18"
            :font-size="16"
            :stroke-width="4"
            :shadow-offset="2"
          />
        </div>

        <div class="mt-0">
          <AppTitle
            text="Les Jours"
            :width="150"
            :height="32"
            :y="22"
            :font-size="28"
            :stroke-width="4"
            :shadow-offset="2"
            text-uppercase
          />
        </div>
        <div class="mb-0 mt-n3">
          <AppTitle
            text="Extraordinaires"
            :width="255"
            :height="32"
            :y="22"
            :font-size="28"
            :stroke-width="4"
            :shadow-offset="2"
            text-uppercase
          />
        </div>

        <div class="bg-tertiary pa-1 font-size-14px">
          Tentez de gagner un séjour en famille au
        </div>

        <div>
          <img
            src="~/assets/images/game3/teasing/mobile/top/logo-parc/logo-parc@2x.png"
            srcset="
              ~/assets/images/game3/teasing/mobile/top/logo-parc/logo-parc@1x.png 1x,
              ~/assets/images/game3/teasing/mobile/top/logo-parc/logo-parc@2x.png 2x,
              ~/assets/images/game3/teasing/mobile/top/logo-parc/logo-parc@3x.png 3x
            "
          />
        </div>

        <div class="position-relative">
          <img
            class="image-button-left"
            src="~/assets/images/game3/teasing/mobile/top/asterix/asterix@2x.png"
            srcset="
              ~/assets/images/game3/teasing/mobile/top/asterix/asterix@1x.png 1x,
              ~/assets/images/game3/teasing/mobile/top/asterix/asterix@2x.png 2x,
              ~/assets/images/game3/teasing/mobile/top/asterix/asterix@3x.png 3x
            "
          />
          <img
            class="image-button-right"
            src="~/assets/images/game3/teasing/mobile/top/idefix/idefix@2x.png"
            srcset="
              ~/assets/images/game3/teasing/mobile/top/idefix/idefix@1x.png 1x,
              ~/assets/images/game3/teasing/mobile/top/idefix/idefix@2x.png 2x,
              ~/assets/images/game3/teasing/mobile/top/idefix/idefix@3x.png 3x
            "
          />

          <AppButtonDisabledWithShadow
            :to="localePath('game-3')"
            width="180px"
            class="font-size-16px"
          >
            Verrouillé
          </AppButtonDisabledWithShadow>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script setup lang="ts">
import Bg1X from '~/assets/images/game3/teasing/mobile/top/confettis/confettis@1x.png'
import Bg2X from '~/assets/images/game3/teasing/mobile/top/confettis/confettis@2x.png'
import Bg3X from '~/assets/images/game3/teasing/mobile/top/confettis/confettis@3x.png'
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.img-pa {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 30px;
  transform: translateY(50%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 30px 0;
}

.outlined {
  color: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 0 5px;
}

.image-button-left {
  position: absolute;
  left: -90px;
  bottom: 0;
}
.image-button-right {
  position: absolute;
  right: -70px;
  bottom: 0;
}
</style>
