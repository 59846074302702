<template>
  <AppButtonBase
    color="primary"
    class="app-cta--disabled-with-shadow text-white app-cta--big"
    disabled
    v-bind="$attrs"
  >
    <slot />
  </AppButtonBase>
</template>
