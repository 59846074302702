<template>
  <!-- by chronological order -->

  <!-- <LandingPagesTF1 /> -->
  <!-- <LandingPagesTF1Done /> -->

  <!-- <LandingPagesTF2Teasing /> -->
  <!-- <LandingPagesTF2 /> -->

  <!-- <LandingPagesTF3Teasing /> -->
  <!-- <LandingPagesTF3 /> -->

  <!-- <LandingPagesShopAlone /> -->

  <component :is="is" />
</template>

<script setup lang="ts">
import {
  LandingPagesTF3Teasing,
  LandingPagesTF3,
  LandingPagesShopAlone,
} from '#components'
const { hasStarted, isDiscontinued } = useGame3()
const is = computed(() =>
  !hasStarted.value
    ? LandingPagesTF3Teasing
    : !isDiscontinued.value
      ? LandingPagesTF3
      : LandingPagesShopAlone,
)
</script>
